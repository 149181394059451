import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Accordion, Icon, Text } from '../../common/components';
import ORGANISERS from './constants/organisers';
import animation from './assets/animations/community.json';
import './_who-we-are.scss';

function OrganisersAccordion({ organisers, title }) {
	return (
		<Accordion title={title} iconName="fa-users">
			<div organisers="who-we-are__accordion">
				{organisers.map(name => (
					<div key={name} className="who-we-are__accordion__organiser">
						<ProfileImage name={name.toLowerCase()} />
						<Text bold>{name}</Text>
					</div>
				))}
			</div>
		</Accordion>
	);
}

function ProfileImage({ name }) {
	const [image, setImage] = useState();
	const [webpImage, setWebpImage] = useState();
	
	useEffect(() => {
		try {
			import(`./assets/profileImages/${name}.png`).then(image => setImage(image.default));
			import(`./assets/profileImages/${name}.webp`).then(image => setWebpImage(image.default));
		} catch {}
	}, []);
	
	return (
		<picture className="who-we-are__profile-image">
			<source srcSet={webpImage} type="image/webp" />
			<source srcSet={image} type="image/png" />
			<img align="left" loading="lazy" alt={`${name}-profile-img`} src={image} />
		</picture>
	)
}
export default function WhoWeAre() {
	return (
		<div className="who-we-are">
			<div className="who-we-are__left-col">
				<Text subHeading>
					We’re people from all around the <Icon name="fa-globe-americas" /> who love to climb.
				</Text>
				<Text>
					Starting in 2020 at a climbing gym, a few like-minded novice climbers decided it would be a good idea to build a more social climbing experience to build friendships, climbing skills and grow together.
				</Text>
				<Text>
					Since this eventful gathering we have increased our weekly climbing gym events and welcome one-time and regular attendees of all abilities.
				</Text>
				<Text className="who-we-are__text">
					We share and solve climbing problems and work together on techniques. You'll often hear about the outdoor climbing and social activities of those who have met in our group and headed out on their own adventures together.
				</Text>
				<OrganisersAccordion organisers={ORGANISERS.NINE_DG_WATERLOO} title="9DG Waterloo Organisers" />
				<OrganisersAccordion organisers={ORGANISERS.NOMAD} title="Nomad Organisers" />
				<OrganisersAccordion organisers={ORGANISERS.ST_PETERS} title="St. Peters Organisers" />
			</div>
			<div className="who-we-are__right-col">
				<Lottie
					isClickToPauseDisabled
					options={{
						loop: true,
						autoplay: true,
						animationData: animation,
					}}
				/>
			</div>
		</div>
	);
}