import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import Icon from '../Icon';
import Text from '../Text';
import { useVisibilityState } from 'webrix/hooks';
import './_accordion.scss';

export default function Accordion({ title, iconName, children }) {
	const { visible, toggle } = useVisibilityState(false);
	
	return (
		<div
			className={cx(
				'accordion',
				{ 'accordion--open': visible }
			)}
		>
			<button className="accordion__header" onClick={toggle}>
				<Text bold subHeading className="mt-0 mb-0"><Icon name={iconName} /> {title}</Text>
				<div className="accordion__header__icon tte-button">
					<Icon name={visible ? 'fa-chevron-up' : 'fa-chevron-down'} colour="white" />
				</div>
			</button>
			<Collapse transition={`height 300ms cubic-bezier(.4, 0, .2, 1)`} isOpen={visible}>
				{children}
			</Collapse>
		</div>
	);
}