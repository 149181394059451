import { Link, useLocation, useNavigate } from 'react-router-dom';
import { stack as Menu } from 'react-burger-menu';
import cx from 'classnames';
import { useBooleanState } from 'webrix/hooks';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import Icon from '../Icon';
import Text from '../Text';
import './_header.scss';

const ROUTES = [
	{ to: '/about', label: 'Who We Are' },
	{ to: '/sessions', label: 'Events & Sessions' },
	{ to: '/contact', label: 'Get In Touch' }
];

function HeaderLink({ to, label, location, setMenuOpen, className }) {
	const { value, setTrue, setFalse } = useBooleanState(location.pathname === to);
	
	useEffect(() => {
		const selected = location.pathname === to;
		
		if (selected === value) { return; }
		
		setMenuOpen?.(false);
		
		if (selected) { return setTrue(); }
		
		setFalse();
	}, [location.pathname]);
	
	return (
		<Link
			to={to}
			className={cx('header__links__link', { 'header__links__link--selected': value }, className)}
		>
			{label}
		</Link>
	);
}
export default function Header() {
	const location = useLocation();
	const navigate = useNavigate();
	const isMobile = useMediaQuery({ query: '(max-width: 850px)' })
	const { value: menuOpen, setValue: setMenuOpen } = useBooleanState(false);
	
	return (
		<div className={cx('header', { 'header--mobile': isMobile })}>
			<button className="header__logo" onClick={() => navigate('/')}>
				<Logo />
			</button>
			{isMobile && <Text heading className="ml-20">{ROUTES.find(({ to }) => to === location.pathname)?.label}</Text> }
			{
				isMobile
					? (
						<Menu right isOpen={menuOpen} onStateChange={({ isOpen }) => setMenuOpen(isOpen)}>
							<div className="header__links--mobile">
								{ROUTES.map(route => (
									<HeaderLink setMenuOpen={setMenuOpen} key={route.to} {...route} location={location} />
								))}
								{/*<HeaderLink setMenuOpen={setMenuOpen} key="gallery" to="/gallery" label="Gallery" location={location} />*/}
								<a href="https://www.facebook.com/ssclimbers" target="_blank" rel="noreferrer">
									<Icon name="fa-facebook" brand />
								</a>
							</div>
						</Menu>
					)
					: (
						<div className="header__links">
							{ROUTES.map(route => <HeaderLink key={route.to} {...route} location={location} />)}
							{/*<HeaderLink*/}
							{/*	key="gallery"*/}
							{/*	to="/gallery"*/}
							{/*	label={<Icon name="fa-photo-video" size={20} />}*/}
							{/*	location={location}*/}
							{/*	className="header__links__link--grow"*/}
							{/*/>*/}
							<a
								className="header__links__link--grow"
								href="https://www.facebook.com/ssclimbers"
								target="_blank"
								rel="noreferrer"
							>
								<Icon name="fa-facebook" brand />
							</a>
						</div>
					)
			}
		</div>
	);
}
