import cx from 'classnames';
import { useEffect, useState } from 'react';
import Icon from '../Icon';
import './_button.scss';

const DEFAULT_TYPE = 'primary';
const SUPPORTED_TYPES = [DEFAULT_TYPE, 'secondary', 'danger', 'icon-only'];
const BUSY_ICON = 'fa-circle-notch';

const ICON_COLOUR_OVERRIDES = {
	[DEFAULT_TYPE]: 'white',
	danger: 'white'
};

export default function Button({
	styleType = DEFAULT_TYPE,
	fullWidth,
	children,
	disabled,
	onClick,
	icon,
	busy,
	className,
	onStateEnd,
	state,
	id,
	dynamicWidth,
	iconSize,
	iconColour,
	type
}) {
	const supportedType = SUPPORTED_TYPES.includes(styleType);
	
	if (!supportedType) {
		console.warn('Button type not supported. Falling back to primary.');
	}
	
	const [showStateIcon, setButtonState] = useState(Boolean(state));
	const busyIcon = showStateIcon ? (state === 'success' ? 'fa-check-circle' : 'fa-times') : BUSY_ICON;
	const prefixIcon = busy || showStateIcon ? busyIcon : icon;
	
	useEffect(() => {
		if (state) {
			setButtonState(true);
			
			const timeout = setTimeout(() => {
				setButtonState(false);
				
				if (!onStateEnd) {
					return;
				}
				
				onStateEnd();
			}, 2000);
			
			return () => {
				if (!timeout) {
					return;
				}
				
				clearTimeout(timeout);
			};
		}
	}, [state, onStateEnd]);
	
	return (
		<button
			disabled={busy || disabled || state}
			className={cx(
				'tte-button',
				`tte-button--${supportedType ? styleType : DEFAULT_TYPE}`,
				{
					'tte-button--full-width': fullWidth,
					'tte-button--icon': icon && !children && styleType !== 'icon-only',
					'tte-button--busy': busy || state,
					'tte-button--dynamic-width': dynamicWidth
				},
				className
			)}
			onClick={onClick}
			id={id}
			type={type}
		>
			<div className="tte-button__content">
				{prefixIcon ? <Icon name={prefixIcon} colour={iconColour || ICON_COLOUR_OVERRIDES[styleType]} spin={prefixIcon === BUSY_ICON} size={iconSize} /> : null}
				{children && <span style={{ opacity: busy || state ? 0 : 1 }}>{children}</span>}
			</div>
		</button>
	);
}