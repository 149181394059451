import { ReactComponent as BoulderingImage } from './assets/bouldering1.svg';
import { ReactComponent as TopRopingImage } from './assets/top_roping1.svg';
import { Accordion, Text } from '../../common/components';
import './_events-and-sessions.scss';

function InfoBlock({ type, place, description, where, whereLink, when, meetupLink }) {
	return (
		<div className="events-and-sessions__info-block">
			<Text subHeading><span style={{ color: '#ff4e75' }}>{type}</span> at {place}</Text>
			<Text>{description}</Text>
			<div className="events-and-sessions__info-block__row">
				<Text bold>Where?</Text>
				<a href={whereLink} target="_blank" rel="noreferrer">{where}</a>
			</div>
			<div className="events-and-sessions__info-block__row">
				<Text bold>When?</Text>
				<Text>{when}</Text>
			</div>
			<Text
				className="events-and-sessions__info-block__small"
				bold
			>
				More information and RSVP on the <a href={meetupLink} target="_blank" rel="noreferrer">meetup group</a>.
			</Text>
		</div>
	)
}

export default function EventsAndSessions() {
	return (
		<div className="events-and-sessions">
			<BoulderingImage />
			<div className="events-and-sessions__content">
				<Text subHeading className="mb-60">Our meetup groups are free to attend! Simply pay your gym entry fee and come join us for:</Text>
				<InfoBlock
					type="Sport Climbing"
					place="St. Peters"
					description="A longer, 3 hour session of climbing at St.Peters. We’ll pair you up with a belay partner and if you’ve never been sport climbing before, we’ll show you the ropes."
					where="Sydney Indoor Climbing Gym, St Peters"
					whereLink="https://goo.gl/maps/VnHXmc1fjVioPf3R6"
					when="Sunday at 4PM"
					meetupLink="https://www.meetup.com/en-AU/sydney-social-climbers/events/tnfhzsyfcgbvb/"
				/>
				<InfoBlock
					type="Bouldering"
					place="9degrees"
					description="A shorter, 2 hour session of climbing at 9degrees, no harness required."
					where="9degrees, Waterloo"
					whereLink="https://goo.gl/maps/bgn4Kri4kgVADe6i7"
					when="Tuesday at 7PM"
					meetupLink="https://www.meetup.com/en-AU/sydney-social-climbers/events/kfpxbtyfcgbpb/"
				/>
				<InfoBlock
					type="Bouldering"
					place="Nomad"
					description="A shorter, 2 hour session of climbing at Nomad, no harness required."
					where="Nomad, Annandale"
					whereLink="https://goo.gl/maps/CasjM81JXgEdz9oL8"
					when="Thursday at 6PM"
					meetupLink="https://www.meetup.com/sydney-social-climbers/events/292694279"
				/>
				<Accordion title="What's The Difference?" iconName="fa-question-circle">
					<Text>
						Bouldering? Sport climbing? These are the two most popular forms of climbing in Sydney, so we do both!
					</Text>
					<Text>
						<strong>Bouldering</strong> has 4-Meter-high walls, accompanied with large, padded mats to cushion your fall. You’re free from any ropes and harness allowing you to move freely and dynamically.
					</Text>
					<Text>
						<strong>Sport climbing</strong> has walls up to 20 meters high, so you definitely need a harness! You’ll also need a partner to 'belay' you (feed you rope). These longer walls might tire you out more but offer longer routes.
					</Text>
				</Accordion>
			</div>
			<TopRopingImage />
		</div>
	);
}