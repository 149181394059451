import cx from 'classnames';
import './_icon.scss';

const COLOUR_MAP = {
	primary: '#ff4e75',
	yellow: '#F4C51B',
	white: '#fff',
	success: '#33C759',
	danger: '#FF453A',
	mid_grey: '#E0E0E0'
};

export default function Icon({
	name,
	className,
	size = 24,
	colour,
	rotate,
	spin,
	id,
	brand
}) {
	return (
		<i
			id={id}
			className={cx('icon', 'text-colour', `fa${brand ? 'b': 's'}`, name, { spin }, className)}
			style={{ fontSize: `${size}px`, color: COLOUR_MAP[colour], transform: rotate ? `rotate(${rotate}deg)` : '' }}
		/>
	);
}
