import { Icon, Text } from '../../common/components';
import { ReactComponent as BoulderingImage } from './assets/bouldering.svg';
import { ReactComponent as TopRopingImage } from './assets/top_roping.svg';
import './_home.scss';

export default function Home() {
	return (
		<div className="home">
			<TopRopingImage className="home__top-roping-image" />
			<Text heading>A group of Sydneysiders who <Icon name="fa-heart" colour="danger" /> all things climbing.</Text>
			<Text className="home__subtitle">We host regular meetups at various climbing gyms across Sydney. Come and meet like-minded people for some climbing fun and a <Icon name="fa-beer" colour="yellow" size={18}/> at the pub! We’re not affiliated with any of the gyms so we can climb our way round Sydney.</Text>
			<BoulderingImage className="home__bouldering-image" />
		</div>
	)
}