export default {
	ST_PETERS: [
		'Adrian',
		// 'Linna',
		'Mike',
		'Fiza',
		'Jason',
		// 'Shancy'
	],
	NINE_DG_WATERLOO: [
		'Alex',
		'Mike',
		'Aurélien',
		'Jason',
		// 'Julie',
		// 'Jesse'
	],
	NOMAD: [
		// 'Martin',
		'Gerardo',
		// 'Travis',
		// 'Kristo'
	]
};