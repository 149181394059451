import { forwardRef } from 'react';
import cx from 'classnames';
import Button from '../Button';
import './_input.scss';

const ENTER_KEY_CODE = 'Enter';
const ESC_KEY_CODE = 'Escape';

function handleKeyDown(handlers) {
	return (e) => {
		if (!handlers[e.code]) {
			return;
		}
		
		handlers[e.code]();
	}
}

function Input({
	placeholder,
	disabled,
	onChange,
	multiline,
	value = '',
	id,
	hasTooltip,
	className,
	onBlur,
	onFocus,
	continueDisabled,
	onContinue,
	small,
	onEscape,
	onEnter,
	maxWidth,
	autoFocus,
	label,
	continueIcon = 'arrow-right'
}, ref) {
	const Element = multiline ? 'textarea' : 'input';
	
	const handleContinue = (fn) => () => {
		fn();
	};
	
	const input = (
		<Element
			disabled={disabled}
			className={cx('input', { 'input--small': small }, !onContinue ? className : void 0)}
			placeholder={placeholder}
			onChange={onChange}
			value={value}
			data-tip={hasTooltip ? 'tooltip' : void 0}
			data-event="preventclick"
			ref={ref}
			id={id}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={
				onEscape || onEnter
					? handleKeyDown({
						[ESC_KEY_CODE]: handleContinue(onEscape),
						[ENTER_KEY_CODE]: handleContinue(onEnter)
					})
					: void 0
			}
			style={{ maxWidth: maxWidth && !onContinue ? `${maxWidth}px` : void 0 }}
			autoFocus={autoFocus}
			name={id}
		/>
	);
	
	if (!onContinue) {
		return input;
	}
	
	return (
		<div
			className={cx('input-continue', { 'input-continue--small': small }, className)}
			style={{ maxWidth: maxWidth ? `${maxWidth}px` : void 0 }}
		>
			{input}
			<Button
				icon={continueIcon}
				iconSize={small ? 13 : void 0}
				disabled={continueDisabled}
				onClick={continueDisabled ? void 0 : handleContinue(onContinue)}
			/>
		</div>
	);
}

export default forwardRef(Input);