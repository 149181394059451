import cx from 'classnames';
import { Header } from 'common/components';
import './_core-layout.scss';

export default function CoreLayout({
  children,
  className,
}) {
  return (
      <div className="core-layout">
        <Header />
        <div className={cx('core-layout__content', className)}>
          {children}
        </div>
      </div>
  );
}
