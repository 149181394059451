import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import CoreLayout from './layouts/CoreLayout';
import WhoWeAre from './pages/WhoWeAre';
import EventsAndSessions from './pages/EventsAndSessions';
import GetInTouch from './pages/GetInTouch';
import NotFound from './pages/NotFound';
// import Gallery from './pages/Gallery';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
};

export default function App() {
  return (
    <>
      <ScrollToTop />
      <CoreLayout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<WhoWeAre />} />
          <Route exact path="/sessions" element={<EventsAndSessions />} />
          <Route exact path="/contact" element={<GetInTouch />} />
          {/*<Route exact path="/gallery" element={<Gallery />} />*/}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </CoreLayout>
    </>
  );
}
