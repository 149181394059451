import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import animation from './assets/animations/discussion.json';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { Button, Text } from '../../common/components';
import './_not-found.scss';

export default function NotFound() {
	const navigate = useNavigate();
	
	return (
		<div className="not-found">
			<Logo />
			<div className="not-found__content">
				<div className="not-found__animation">
					<Lottie
						isClickToPauseDisabled
						options={{
							loop: true,
							autoplay: true,
							animationData: animation,
							rendererSettings: {
								preserveAspectRatio: 'xMinYMin slice'
							}
						}}
					/>
				</div>
				<div className="not-found__content__help">
					<Text subHeading>This route didn't go anywhere. Maybe try a heel-hook next time?</Text>
					<Button onClick={() => navigate('/')}>Take Me Home</Button>
				</div>
			</div>
		</div>
	);
}