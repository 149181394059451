import Lottie from 'react-lottie';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import animation from './assets/animations/testimonial.json';
import { ReactComponent as ArrowImage } from './assets/images/arrow.svg';
import { Icon, Text } from '../../common/components';
import './_get-in-touch.scss';

export default function GetInTouch() {
	return (
		<div className="get-in-touch">
			<Lottie
				isClickToPauseDisabled
				options={{
					loop: false,
					autoplay: true,
					animationData: animation,
					rendererSettings: {
						preserveAspectRatio: 'xMinYMin slice'
					}
				}}
			/>
			<div className="get-in-touch__content">
				<Text>Any questions, thoughts or ideas? Drop us a message and we’ll try and get back to you as soon as possible. You can also pop along to either of our meetups and have a chat with any of the organisers!</Text>
				<Text subHeading>We use Facebook Messenger! Click the <Icon name="fa-facebook-messenger" colour="primary" brand/> button to chat to us.<Text className="mt-10">Give it a few seconds to appear.</Text></Text>
				<ArrowImage className="get-in-touch__content__arrow" />
				<MessengerCustomerChat pageId="103706628841930" appId="" themeColor="#ff4e75" />
			</div>
		</div>
	);
}